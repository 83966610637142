import { http } from '../../../../helpers/easyindustriaapi/config'; // importe seu módulo http

export default {
    async getData(currentEmpresa, onLoader, offLoader, makeToast) {
        // Ativa o carregamento na tela
        onLoader();

        try {
            // Faz uma requisição HTTP para a URL /unidade?empresa_id=<id da empresa>
            let response = await http.get('/unidade?empresa_id=' + currentEmpresa.id);

            // Se a resposta da requisição tiver o status 200:
            if (response.status === 200) {
                // Armazena os dados no array T_unidades
                const T_unidades = response.data;

                // Aguarda dois segundos antes de desativar o carregamento na tela
                setTimeout(() => {
                    offLoader();
                }, 2000);

                // Retorna o array T_unidades
                return T_unidades;
            } else {
                // Caso a resposta não tenha o status 200, exibe uma mensagem de erro na tela
                makeToast('danger', 'Erro ao carregar lista');

                // Desativa o carregamento na tela
                offLoader();

                // Retorna um array vazio
                return [];
            }
        } catch (error) {
            // Caso ocorra um erro na requisição, desativa o carregamento na tela
            offLoader();

            // Se houver uma resposta de erro na requisição:
            if (error.response) {
                // Verifica se o status é 404
                if (error.response.status === 404) {
                    // Se for 404, exibe uma mensagem de erro na tela informando que o destino URL não foi encontrado
                    makeToast('danger', 'Destino URL não encontrado!');
                } else {
                    // Caso contrário, exibe a mensagem de erro da resposta na tela
                    makeToast('danger', error.response);
                }
            }

            // Retorna um array vazio
            return [];
        }
    },

    async doPost(unidade, currentEmpresa, currentUser, getData, onLoader, offLoader, makeToast) {
        // Configura os campos adicionais da unidade antes do envio
        unidade.empresa_id = currentEmpresa.id;
        unidade.user_id = currentUser.id;
        console.log(unidade)
        onLoader();
        try {
            let response = await http.post('/unidade?empresa_id=' + currentEmpresa.id, unidade);
            if (response) {
                offLoader();
                if (response.status === 200) {
                    getData();
                    makeToast('success', 'Registro incluído');
                }
            }
        } catch (error) {
            offLoader();
            if (error.response) {
                if (error.response.status === 404) {
                    makeToast('danger', 'Destino URL não encontrado!');
                } else {
                    makeToast('danger', error);
                }
            }
        }
    },

    async doPut1(unidade, currentEmpresa, getData, onLoader, offLoader, makeToast) {
        onLoader();
        try {
            let response = await http.put('/unidade/' + unidade.id + '?empresa_id=' + currentEmpresa.id, unidade);
            if (response) {
                offLoader();
                if (response.status === 200) {
                    makeToast('success', 'Registro alterado');
                    getData();
                    //editando = false;
                }
            }
        } catch (error) {
            offLoader();
            if (error.response) {
                if (error.response.status === 404) {
                    makeToast('danger', 'Destino URL não encontrado!');
                } else {
                    makeToast('danger', error);
                }
            }
        }
    },
    async doDelete(unidade, currentEmpresa, getData, onLoader, offLoader, makeToast) {
           onLoader();
        try {
          let response = await http.delete('/unidade/'+unidade.id+'?empresa_id='+currentEmpresa.id, unidade);
          if (response) {
            
            if (response.status === 200) {
              getData();
              offLoader();
              makeToast('warning', 'Registro excluído');
            }
          }
        } catch (error) {
            offLoader();
          if (error.response) {
            if (error.response.status === 404) {
                makeToast('danger', 'Destino URL não encontrado!');
            } else {
                makeToast('danger', error);
            }
          }
        }
      },
}
<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";

export default {
  //components: { Multiselect },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Incluir Unidade de Medidada',
      uni_med:{
        DescricaoUn: 'metros',
        un_codigo: 'M'
      }
    }
  },
  validations() {
    return {
      unidade_med: {
        DescricaoUn: { required },
        un_codigo: { required }
       
      }
    }
  },
  
  mounted() {
      this.setNewTitle();
  },
  methods: {
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPost() {
      this.$emit('doPost', this.uni_med);
    },
  },
    
}
</script>

<template>
<div class="card">
  <div class="card-body">
    <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
    <form id="form-tipo-movimento" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <h4>Unidade de Medida</h4>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_sigla_id" class="col-form-label">Sigla</label>
                  <div class="col">
                    <input v-model="uni_med.un_codigo" class="form-control text-leftt" type="text" placeholder="digite a Sigla" id="sigla">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_unidade_id" class="col-form-label">Unidade</label>
                  <div class="col">
                    <input  v-model="uni_med.DescricaoUn" class="form-control text-left" type="text" placeholder="digite a unidade" id="unidade">
                  </div>
                </div>
              </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
      <button class="btn btn-light"
       @click="setPost">Gravar</button>
    </div>
  </div>
</div>
</template>
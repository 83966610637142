<script>
export default {
  props: {
    unidade: { type: Object }
  },
}
</script>

<template>
<div id="Unidade">
  <p>Sigla: {{ unidade.DescricaoUn }}</p>
  <p>Unidade: {{ unidade.un_codigo}} </p>
  <p>ID_empresa: {{ unidade.empresa_id}} </p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ unidade.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ unidade.updated_at }}</b-badge></p>
</div>
</template>
